
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        previewVisible: {
            type: Boolean,
            requried: true,
            default: false,
        },
        fileType: {
            type: String,
            requried: true,
            default: '',
        },
        base64code: {
            type: String,
            requried: true,
            default: '',
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const close = () => {
            emit('close');
        };
        return {
            close,
        };
    },
});
